import React from "react";

export const About = (props) => {
  return (
    <div id="about" className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-2">
            {" "}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="about-text">
              <div className="section-title">
                <h2>
                  About Us
                </h2> 
                <p>
                  Lambourg Renovations, LLC specializes in custom home renovations and design. 
                  Our services include custom kitchens/baths, interior/exterior remodeling, carpentry, flooring, painting, electrical, and plumbing.
                </p>
                <p>
                  We are an insured LLC with over 20 years of experience. Our customers remain loyal due to our commitment to excellence and attention to detail. 
                  References are available upon request.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
