import { React, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

export const Gallery = (props) => {

  const [photoURLs, setPhotoURLs] = useState([]);

  // API URL to fetch the image URLs for the gallery
  const apiUrl = `api/getImageURLs`;

  // Fetch photo URLs from Netlify function
  useEffect(() => {
    async function fetchPhotoURLs() {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.error) {
          console.error('Error fetching data:', data.error);
          return;
        }

        setPhotoURLs(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchPhotoURLs();
  }, [apiUrl]);

  // List of image metadata for the ImageGallery
  const images = photoURLs.map(url => {
    return {
      original: url,
      thumbnail: url,
      originalHeight: 500,
    }
  });

  return (
    <div id="portfolio" className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-2">
            {" "}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="section-title">
              <h2>Work Samples</h2>
            </div>
            <div className="row">
              <ImageGallery items={images} />;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
