import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import React from "react";

const EMAIL_JS_CONFIG = {
  serviceID: "service_2g9ni1k",
  templateID: "lbrn_contact_template",
  userID: "bZsa7uTHdu1zEc_Hq"
}

const initialFormState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setFormState] = useState(initialFormState);
  const [submitButtonText, setSubmitButtonText] = useState("Send Message");
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setFormState({ ...initialFormState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm(EMAIL_JS_CONFIG.serviceID, EMAIL_JS_CONFIG.templateID, e.target, EMAIL_JS_CONFIG.userID)
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          formRef.current.reset();
          setSubmitButtonText("Message Sent!");
        },
        (error) => {
          console.log(error.text);
          setSubmitButtonText("An Error Occured!");
        }
      );
  };
  return (
    <div>
      <div id="contact" className="section">
        <div className="container">
          <div className="col-md-2">
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contact Us</h2>
                <p>
                  Please email us at <b>lambourg.renovations@gmail.com</b>
                  or fill out the form below and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit} ref={formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {submitButtonText}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        {/* empty footer */}
      </div>
    </div>
  );
};
